:root {
	--primary-dark: #2C292C;
	--popup-header-bg: #2C292C;

	--offcanvas-width: 578px;

	--header-font-size: 1.1rem;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Inter',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #FBF8F8;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.grecaptcha-badge { 
	visibility: hidden !important;
}

.Toastify__toast-container {
    z-index: 9999999 !important;
}

.btn-dark {
	background-color: #2C292C;
}
.btn-dark .ico {
	border: 1px dashed #aaa;
    padding: 3px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    text-align: center;
}

.btn-white {
	border: 1px solid #CFC8CF;
	background-color: #fff;
}
.btn-white:hover,
.btn-white:active {
	border-color: #CFC8CF;
	background-color: #f3f3f3;
}
.btn-white:disabled,
.btn-white.disabled {
	border-color: #CFC8CF;
}


.form-check-input:checked {
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
}

.status-badge {
	border: 1px solid #19BD9D;
	background-color: #E0FFF9;
	width: 70px;
	height: 24px;
	padding: 0px 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 40px;
	margin: 10px 0 12px;
}

.status-badge.active {
	border: 1px solid #19BD9D;
	background-color: #E0FFF9;
}

.status-badge.testing {
	border: 1px solid #4D474D;
	background-color: #FBF8F8;
}

.status-badge.archived,
.status-badge.expired {
	border: 1px solid #CFC8CF;
	background-color: #FFFFFF;
}

.status-badge span.dot {
	background-color: #000;
	width: 6px;
	height: 6px;
	display: block;
	border-radius: 50%;
	margin: 0 4px 0 0;
}

.status-badge.active span.dot {
	background-color: #19BD9D;
}

.status-badge.testing span.dot {
	background-color: #4D474D;
}

.status-badge.archived span.dot,
.status-badge.expired span.dot {
	background-color: #CFC8CF;
}

.status-badge span.label {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #000;
}

.status-badge.active span.label {
	color: #19BD9D;
}

.status-badge.testing span.label {
	color: #4D474D;
}

.status-badge.archived span.label,
.status-badge.expired span.label {
	color: #CFC8CF;
}


/* START - Password Field */
.password-complexity-section {
	display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-start;
}
.password-complexity-section .password-status {
	min-width: 210px;
    font-size: 0.9rem;
	color: #444;
}
.password-complexity-section .password-status:before {
	content: '';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #333;
	display: inline-block;
	margin-right: 5px;
}
.password-complexity-section .password-status.is-valid:before {
	background-color: #31bf7d;
}
/* END - Print card */


/* START - Print card */
.print-card {
	margin: 10mm;
}

.print-content {
	display: none;
}

@media print {
	/* @page {
		size: 88mm 62mm;
	} */
	html,
	body {
		height: initial !important;
		overflow: initial !important;
	}

	.print-content {
		display: initial;
	}
}

/* END - Print card */


.card.page-card {
	background-color: #fff;
	border: 2px solid #fff;
	border-radius: 18px;
	box-shadow: 0px 8px 24px 0px #ACA6A63D;
	padding-top: 2px;
	overflow: hidden;
}

.cp_splash {
	background-color: var(--primary-dark);
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.cp_main_blckbx {
	padding: 50px;
	background: #FBF8F8;
	height: 100vh;
}

.cp_blckbx {
	background: var(--primary-dark);
	border-radius: 16px;
	padding: 50px 30px;
	box-shadow: 70px 70px 74px rgb(44 41 44 / 60%);
	position: relative;
	height: calc(100vh - 100px);
	max-width: 400px;
}

.cp_blckbx h1 {
	color: #fff;
}

.cp_blckbx_logo {
	max-width: 198px;
	position: absolute;
	bottom: 50px;
}

@media (max-width: 768px) {
	.cp_main_blckbx {
		height: auto;
		padding: 30px;
	}

	.cp_blckbx {
		padding: 30px;
		width: 100%;
		max-width: 100%;
		box-shadow: 20px 20px 74px rgb(44 41 44 / 60%);
	}
}

/* Form start */

.cp_main-form {
	/* margin-left: 200px;  */
	max-width: 410px;
	height: calc(100vh - 60px);
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	position: relative;
}

.cp_main-form h2 {
	font-size: 26px;
	line-height: 40px;
	color: #4D474D;
	position: relative;
	font-weight: 700;
}

.cp_main-form h2:after {
	position: absolute;
	bottom: -10px;
	width: 90px;
	height: 2px;
	background: #ACA6A6;
	content: '';
	left: 0;
}
@media (min-width: 768px) {
	.cp_main-form {
		height: calc(100vh - 100px);
	}
}
.cp_frm_inp input {
	width: 100%;
	background: #fff;
}

.cp_form {
	display: flex;
	justify-content: center;
	flex-direction: column;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.cp_frm_inp input {
	height: 50px;
	border-radius: 8px;
	padding: 0 20px;
	border: 1px solid #ACA6A6;
	font-size: 16px;
}

.cp_frm_inp input::placeholder {
	color: #CFC8CF;
}

.cp_frm_inp label {
	/* margin-top: 30px; */
}

.cp_frm_btn button {
	box-shadow: 0 0 12px #2C292C52;
	background: #2C292C;
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	color: #fff;
	width: 100%;
	border-radius: 8px;
	border: none;
	padding: 15px 20px;
	text-align: left;
	display: flex;
	justify-content: space-between;
}

.cp_frm_btn .btn-theme {
	background: #fff;
	border: 1px solid #ACA6A6;
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	color: #2C292C;
	width: 100%;
	border-radius: 8px;
	border: none;
	padding: 15px 20px;
	text-align: left;
	display: flex;
	justify-content: space-between;
	box-shadow: 0px 12px 24px 0px #2C292C52;
}

.cp_forgot_pass {
	display: flex;
	justify-content: space-between;
	/* font-size: 16px; */
	/* color: #4D474D; */
	margin-top: 12px;
}

.cp_forgot_pass a {
	font-size: 16px;
	color: #4d474d;
	font-weight: 500;
	/* text-decoration: none; */
}

.cp_forgot_pass a:nth-child(2) {
	color: #2C292C;
}

.cp_frm_btn.cp_light button {
	background: #fff;
	color: #000;
}

.cp_frm_btn.cp_light button img {
	filter: invert(1);
}

.cp_frm_btn.cp_light button {
	background: #fff;
	color: #000;
}

.cp_frm_btn.cp_light button img {
	filter: invert(1);
}

.cp_account_bx p {
	position: relative;
	font-size: 16px;
	color: #4d474d;
	text-align: center;
	background: #fbf8f8;
	/* display: inline-block; */
	margin: 0 auto;
	padding: 5px 10px;
	z-index: 18;
	width: 35px;
}

.cp_account_bx p:after {
	/* position: absolute; */
	/* height: 1px; */
	/* width: 1px; */
	/* top: 18px; */
	left: 0;
	background: #aca6a6;
	content: '';
	z-index: 3;
}

.cp_account_bx {
	position: relative;
}

.cp_seperation-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
}

.cp_seperation-box hr {
	width: 45%;
	display: block;
}

.cp_seperation-box span.or {
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #4D474D;
}

/* Form End  */

/* check box css Start */
.cp_checkbox label {
	cursor: pointer;
	display: block;
	position: relative;
	padding-left: 25px;
	/* 10 px padding right + 15px input type*/
	margin-right: 10px;
	/* margin between inputs types */
}

.cp_checkbox label:before {
	content: "";
	width: 18px;
	height: 18px;
	position: absolute;
	left: 0;
}

.cp_checkbox input[type=checkbox],
.cp_checkbox input[type=radio] {
	display: none;
}

.cp_checkbox label:before {
	background: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.6 4.2C1.6 5.536 1 7.2 1 9C1 13.416 4.584 17 9 17C13.416 17 17 13.416 17 9C17 4.584 13.416 1 9 1C7.856 1 6.75998 1.23998 5.77598 1.67998" stroke="%23ACA6A6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/></svg>');
	margin-top: 2px;
}

.cp_checkbox input[type=checkbox]:checked+label:before,
.cp_checkbox input[type=radio]:checked+label:before {
	background: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 9.69L7.61 11.3L11.91 7" stroke="%23ACA6A6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/><path d="M2.6 4.2C1.6 5.536 1 7.2 1 9C1 13.416 4.584 17 9 17C13.416 17 17 13.416 17 9C17 4.584 13.416 1 9 1C7.856 1 6.75998 1.23998 5.77598 1.67998" stroke="%23ACA6A6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/></svg>') left center no-repeat;
	filter: brightness(0.5);
}

/* check box css End */


/* header start */
header {
	background: #2c292c;
	position: relative;
}

header .row.align-items-center {
	min-height: 60px;
}

header p {
	color: #fff;
	font: normal 600 20px/40px 'Inter';
	font-size: var(--header-font-size);
	margin: 0;
	text-align: center;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

header .header-logo {
	position: absolute;
	left: 25px;
	top: 50%;
	transform: translateY(-50%);
}

header .btn-header-back {
	position: absolute;
	/* left: 25px; */
	left: calc(var(--bs-gutter-x) * .5);
	top: 50%;
	transform: translateY(-50%);
	background: #1d1b1d;
	border-radius: 12px;
	padding: 3px;
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
}

header .btn-header-back:hover {
	background: #000;
}

header .btn-header-back > svg {
	transform: rotateY(180deg);
}

header .toggle-event-style {
	position: absolute;
	right: 25px;
	top: 50%;
	transform: translateY(-50%);
	background: #000;
	border-radius: 12px;
	padding: 3px;
}

header .toggle-event-style .btn-event-toggle-btn {
	background: #2c292c;
	border-radius: 11px;
	width: 48px;
	height: 48px;
}

header .toggle-event-style .btn-event-toggle-btn.is-active {
	background: #1d1b1d;
}

@media (max-width: 576px) {
	header .toggle-event-style {
		right: 15px;
	}
}

header .offline-badge {
	position: absolute;
	right: 25px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.8rem;
	color: #ddd;
}
@media (max-width: 576px) {
	header .offline-badge {
		right: 15px;
	}
}
header .offline-badge span.dot {
	background-color: #dc3545;
	width: 6px;
	height: 6px;
	display: block;
	border-radius: 50%;
	margin: 0 4px 0 0;
}
 
/* header End */

/* bullet check in / event Start */
.cp_main_event {
	background: #fbf8f8;
	padding: 30px;
	height: 92vh;
}

.cp_bullte-bx {
	padding: 70px 80px;
	border-radius: 16px;
	background-color: #fff;
	height: calc(100vh - 150px);
	position: relative;
}

.cp_bullte-bx h3 {
	font-size: 40px;
	line-height: 80px;
	font-weight: 600;
	margin-bottom: 100px;
}

.cp_bullte-bx p {
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	margin-bottom: 10px;
	color: #ACA6A6;
}

.cp_bullte-bx ul {
	list-style: none;
	height: 240px;
	margin-bottom: 40px;
}

.cp_bullte-bx ul li {
	font-size: 24px;
	line-height: 48px;
	font-weight: 500;
	position: relative;
	color: #4D474D;
	padding-left: 30px;
}

.cp_bullte-bx ul li:before {
	position: absolute;
	left: 0;
}

.cp_bullte-bx ul li:before {}

.cp_bullte-bx ul li:before {
	background: #ACA6A6;
	height: 4px;
	width: 12px;
	left: 0;
	position: absolute;
	content: '';
	top: 20px;
}


/* bullet check in / event End */


/* event Start */

.sidebar {
	background: #1C1A1C;
	margin-top: 60px;
	width: 270px !important;
	height: calc(100vh - 60px);
	height: calc(100dvh - 60px);
}


.cp_main_app_bx {
	display: flex;
	height: calc(100vh - 140px);
	/* 80px header + 80px footer */
}


.settings-dv {
	background: #2C292C;
	padding: 20px;
	border-radius: 8px;
}

.settings-dv h5 {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #8C838C;
}

.settings-dv hr {
	border-color: #4D474D;
	margin: 10px 0;
}

.settings-dv .entrance {}

.settings-dv .entrance h4 {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.settings-dv .entrance span.ico {
	height: 20px;
	position: relative;
	top: -3px;
}


.settings-dv .entrance span.ico {
	height: 20px;
	position: relative;
	top: -3px;
}

.settings-dv .accordion {}

.settings-dv .accordion .accordion-item {
	background: transparent;
	border: 0;
}

.settings-dv .accordion .accordion-item .accordion-header {
	background: transparent;
}

.settings-dv .accordion .accordion-item .accordion-header button.accordion-button {
	background: transparent;
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #ACA6A6;
	padding: 0;
	box-shadow: 0 0 0 0;
}

.settings-dv .accordion .accordion-item .accordion-collapse.collapse.show {}

.settings-dv .accordion .accordion-item .accordion-collapse {}

.settings-dv .accordion .accordion-item .accordion-collapse .accordion-body {
	padding: 10px 0 0;
}

.settings-dv .accordion .accordion-item .accordion-header button.accordion-button::after {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1L5 5L9 1' stroke='%23CFC8CF' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round'/%3e%3c/svg%3e ");
	background-repeat: no-repeat;
	background-size: 10px;
	position: relative;
	top: -7px;
	left: -5px;
	transition: 0s ease;
}

.settings-dv .accordion .accordion-item .accordion-header button.accordion-button.collapsed::after {
	top: 7px;
	left: 5px;
}

.settings-dv .accordion .accordion-item .accordion-header button.accordion-button.collapsed {
	color: #fff;
}

.settings-dv .accordion .accordion-item .accordion-collapse .accordion-body label.form-label {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
}

.settings-dv .accordion .accordion-item .accordion-collapse .accordion-body .form-switch {}

.settings-dv .accordion .accordion-item .accordion-collapse .accordion-body .form-switch input.form-check-input {
	background: #1A171A;
	border-color: #1A171A;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='4' cy='4' r='4' fill='%232C292C'/%3e%3c/svg%3e ");
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 12px;
	width: 28px;
	height: 18px;
	box-shadow: 0 0 0 0;
}

.settings-dv .accordion .accordion-item .accordion-collapse .accordion-body .form-switch input.form-check-input:checked {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='4' cy='4' r='4' fill='%23ACA6A6'/%3e%3c/svg%3e");
	background-position: right center;
	background-size: 12px;
	width: 28px;
	height: 18px;
	box-shadow: 0 0 0 0;
}

.btn-menu.btn {
	background: transparent;
	border: 0;
	padding: 0;
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
}


.loggedIn-dv {
	background: #2C292C;
	padding: 20px;
	border-radius: 8px;
	position: absolute;
	bottom: 20px;
	width: calc(100% - 32px);
}

.loggedIn-dv h5 {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #8C838C;
	margin: 0 0 0;
}

.loggedIn-dv h4 {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 30px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
}

.loggedIn-dv hr {
	border-color: #4D474D;
	margin: 10px 0;
}

.loggedIn-dv button.btn.btn-contained {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
	padding: 0;
}

.loggedIn-dv button.btn.btn-contained i {
	margin-right: 10px;
}

.cp_nav {
	width: 100px;
	background: #000;
	height: calc(100vh - 80px);
	position: relative;
}

footer .cp_nav_btn {
	border: 0;
	color: rgb(255, 255, 255);
	background: #2C292C;
	padding: 13px 16px;
	border-radius: 12px;
	/* font-family: fontawesome; */
	/* position: absolute; */
	/* bottom: 20px; */
	/* left: 0; */
	/* font-size: 10px; */
	/* right: 0; */
	/* width: 53px; */
	/* margin: 0 auto; */
}

/* START EVENT CARD */
.eventCardsList {
	display: flex;
	flex-wrap: wrap;
}

.event-card-grid {
	width: calc(25% - var(--bs-gutter-x));
	margin-left: calc(var(--bs-gutter-x)* .5);
	margin-right: calc(var(--bs-gutter-x)* .5);
	margin-bottom: var(--bs-gutter-x);
	box-shadow: 0 0 24px 0 rgba(44, 41, 44, 0.2);
	border-radius: 16px;
	position: relative;
	border: 2px solid #fff;
}

.event-card-grid .card-img-top {
	border-radius: 14px;
	overflow: hidden;
	display: flex;
	align-items: flex-start;
	justify-content: center;	
}
.event-card-grid .card-img-top img {
	width: 100%;
	min-height: 100%;
}

.event-card-grid .card-title {
	font: normal 500 20px / 20px 'Inter';
	color: #2C292C;
	margin-bottom: 15px;
	font-family: Inter;
	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.05em;
	text-align: left;
}

.event-card-grid .card-text {
	font: normal 600 12px / 20px 'Inter';
	color: #8C838C;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.event-card-grid .card-text i {
	margin-right: 4px;
	position: relative;
	top: -2px;
}

.event-card-grid .arrow_btn {
	background: #000;
	padding: 0;
	border-radius: 100px;
	width: 30px;
	height: 30px;
	display: block;
	box-shadow: 0 8px 24px 0px #2C292C;
	text-align: center;
	display: block;
	align-self: flex-end;
	margin-right: 1rem;
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (max-width: 992px) {
	.event-card-grid {
		width: calc((100% / 3) - var(--bs-gutter-x));
	}

	.event-card-grid .card-img-top {
		height: 200px;
	}

	.event-card-grid .card-title {
		font-size: 18px;
		line-height: 18px;
	}
}

@media (max-width: 768px) {
	.event-card-grid {
		width: calc(50% - var(--bs-gutter-x));
	}
}

@media (max-width: 576px) {
	.event-card-grid {
		border-radius: 6px;
	}

	.event-card-grid .card-body {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.event-card-grid .card-img-top {
		border-radius: 6px;
	}

	.event-card-grid .card-title {
		font-size: 16px;
		line-height: 16px;
	}

	.event-card-grid .card-text {
		font: normal 600 10px / 16px 'Inter';
		margin-bottom: 5px;
	}
}



/* // card list */
.event-card-list {
	width: 100%;
	margin-bottom: var(--bs-gutter-x);
	box-shadow: 0 0 24px 0 rgba(44, 41, 44, 0.2);
	border-radius: 16px;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem;
	border: 1px solid #fff;
}

.event-card-list .card-body {
	flex: 1;
}

.event-card-list img {
	border-radius: 16px;
	align-self: center;
	width: 100px;
	height: 100px;
}

.event-card-list .card-title {
	font: normal 500 20px / 20px 'Inter';
	color: #2C292C;
	margin-bottom: 15px;
	font-family: Inter;
	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.05em;
	text-align: left;
	margin-bottom: 0;
}

.event-card-list .card-text {
	font: normal 600 12px / 20px 'Inter';
	color: #8C838C;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

.event-card-list .card-text i {
	margin-right: 4px;
	position: relative;
	top: -2px;
}

.event-card-list .arrow_btn {
	background: #000;
	padding: 0;
	border-radius: 100px;
	width: 30px;
	height: 30px;
	max-width: 30px;
	box-shadow: 0 8px 24px 0px #2C292C;
	text-align: center;
	margin-right: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (max-width: 768px) {
	.event-card-list .card-title {
		margin-bottom: 10px;
	}
}

@media (max-width: 576px) {
	.event-card-list {
		margin-bottom: 1rem;
	}

	.event-card-list .card-title {
		font-size: 16px;
		line-height: 16px;
	}

	.event-card-list .card-text {
		font: normal 600 10px / 16px 'Inter';
		width: calc(100vw - 205px);
	}

	.event-card-list img {
		width: 80px;
		height: 80px;
	}
}

/* END EVENT CARD */



.cp_card_event h5 {
	font: normal 500 20px/20px 'Inter';
	color: #2C292C;
	margin-bottom: 15px;
	font-family: Inter;
	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.05em;
	text-align: left;
}

.cp_card_event p {
	font: normal 600 12px/20px 'Inter';
	color: #8C838C;
	display: flex;
}

.cp_card_event p i {
	width: 20px;
	height: 20px;
	display: block;
	margin-right: 4px;
	position: relative;
	top: -2px;
}

.cp_card_event.card {
	border: none;
	box-shadow: 0 0 8px 0 #ACA6A6;
	border-radius: 16px;
	position: relative;
	margin-bottom: 20px;
	border: 2px solid #fff;
}

.cp_card_event.card .card-img-top {
	border-radius: 16px;
	margin-bottom: 10px;
}

.card.cp_card_event figure {
	height: 300px;
	overflow: hidden;
	border-radius: 16px;
	margin: 0;
	align-items: center;
	display: flex;
}

.card.cp_card_event figure img.card-img-top {
	width: 100%;
	height: 100%;
}

.cp_events_bx {
	padding: 0;
	/* height: calc(100vh - 80px); */
	overflow-y: scroll;
	flex-grow: 1;
	background: #FBF8F8;
}

.cp_circle_btn {
	background: #000;
	padding: 0;
	border-radius: 100px;
	width: 30px;
	height: 30px;
	display: block;
	box-shadow: 0 8px 24px 0px #2C292C;
	text-align: center;
	position: absolute;
	right: 18px;
	bottom: 28px;
}

/* event End */

/* Print modal Start */
.printModal {}

.printModal .modal-dialog {
	/* max-width: 400px; */
}

.printModal .modal-dialog .modal-content {
	border-radius: 14px;
	overflow: hidden;
}


.printModal .modal-dialog .modal-content .modal-body {
	background: #fefbfe;
	padding: 25px;
}


.printModal .modal-dialog .modal-content .modal-body .print-dv {
	background: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0px 2px 6px 6px #cccccc21;
}

.printModal .modal-dialog .modal-content .modal-body .printFlex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.printModal .modal-dialog .modal-content .modal-body .orientation {}

.printModal .modal-dialog .modal-content .modal-body .printFlex .dropdown {}

.printModal .modal-dialog .modal-content .modal-body .printFlex h4 {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #8C838C;
	margin: 0;
}

.printModal .modal-dialog .modal-content .modal-body .printFlex .dropdown button {
	background: transparent;
	border: 0px;
	color: #4D474D;
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: right;
	padding-right: 10px;
}

.printModal .modal-dialog .modal-content .modal-body .printFlex .dropdown button:after {
	border: 0px;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1L5 5L9 1' stroke='%23CFC8CF' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round'/%3e%3c/svg%3e ");
	width: 10px;
	height: 10px;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	top: 4px;
	right: -5px;
}

.printModal .modal-dialog .modal-content .modal-body .printFlex .printerList .dropdown button:after {
	transform: rotate(-90deg);
}

.printModal .modal-dialog .modal-content .modal-body .printFlex .paperSize {
	display: flex;
	align-items: center;
}

.printModal .modal-dialog .modal-content .modal-body .printFlex .paperSize h5 {
	font-family: Inter;
	font-size: 10px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: right;
	color: #8C838C;
	margin: 0;
}

.printModal .modal-dialog .modal-content .modal-body .printFlex .paperSize span.ico {
	height: 20px;
	width: 20px;
	margin-left: 8px;
	position: relative;
	top: -3px;
}

.printModal .modal-dialog .modal-content .modal-body .orientation h4 {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #8C838C;
	margin: 0 0 10px;
}

.printModal .modal-dialog .modal-content .modal-body .orientation .orientation-dv {
	display: flex;
	align-items: center;
}

.printModal .modal-dialog .modal-content .modal-body .orientation .orientation-dv .vertical-print {
	width: 80px;
	height: 100px;
	display: flex;
	align-items: center;
	border: 1px solid #CFC8CF;
	justify-content: center;
	border-radius: 4px;
	margin-right: 15px;
}

.printModal .modal-dialog .modal-content .modal-body .orientation .orientation-dv .vertical-print h5 {
	font-family: Inter;
	font-size: 8px;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #4D474D;
}

.printModal .modal-dialog .modal-content .modal-body .orientation .orientation-dv .horizontal-print {
	width: 100px;
	height: 80px;
	display: flex;
	align-items: center;
	border: 1px solid #CFC8CF;
	justify-content: center;
	border-radius: 4px;
}

.printModal .modal-dialog .modal-content .modal-body .orientation .orientation-dv .horizontal-print h5 {
	font-family: Inter;
	font-size: 8px;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #4D474D;
}

.printModal .modal-dialog .modal-content .modal-body .orientation .orientation-dv .vertical-print.selected,
.printModal .modal-dialog .modal-content .modal-body .orientation .orientation-dv .horizontal-print.selected {
	box-shadow: 0px 12px 24px 0px #0000003D;
}

.printSwitch {}

.printSwitch .form-switch {}

.printSwitch .form-switch input {
	background-color: #1F1C1F;
	border-color: #1F1C1F;
	width: 30px;
	height: 20px;
	box-shadow: 0 0 0 0;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='8' fill='white'/%3e%3c/svg%3e ");
	background-position: left center;
}

.printSwitch .form-switch input:checked {
	background-position: right center;
}

/* Print Modal End */


/* Opening Ceremony */
.cp-ceremony {
	height: 95%;
	background: #fff;
	box-shadow: 0px 8px 24px 0px #ACA6A63D;
	border-radius: 16px;
	padding: 30px;
	margin: 10px 30px;
	margin-left: calc(1.5rem * .5);
	margin-right: calc(1.5rem * .5);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	overflow: hidden;
}

.cp-ceremony:before {
	content: "";
	background-image: url('../images/ceremony-top-bg.png');
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 0;
}

.cp-ceremony:after {
	content: "";
	background-image: url('../images/ceremony-footer-bg.png');
	background-repeat: no-repeat;
	width: 386px;
	height: 100%;
	position: absolute;
	background-position: right bottom;
	right: 0;
	bottom: 0;
}

.cp-ceremony .content-dv {
	height: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	z-index: 1;
}

.cp-ceremony button {
	z-index: 1;
}

.cp-ceremony .content-dv h1 {
	font-family: Inter;
	font-size: calc(2rem + 1.5vw);
	font-weight: 700;
	line-height: 80px;
	letter-spacing: -0.05em;
	text-align: center;
	color: #2c292c;
	margin: 0 0 40px;
}

.cp-ceremony .content-dv .syncing {
	width: 300px;
}

.cp-ceremony .content-dv .syncing .progress {
	height: 6px;
	background: #EFEEEF;
	margin: 0 0 10px;
}

.cp-ceremony .content-dv .syncing .progress .progressBar {
	background: linear-gradient(90deg, #ACA6A6 0%, #2C292C 100%);
	height: 100%;
	width: 1%;
}

.cp-ceremony .content-dv .syncing .labels {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cp-ceremony .content-dv .syncing .labels p {
	margin: 0;
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #8C838C;
}

/* Opening Ceremony End */

button.btn-theme.btn {
	box-shadow: 0px 12px 24px 0px #2C292C52;
	height: 50px;
	border-radius: 8px;
	background: #2C292C;
	border-color: #2C292C;
	font-family: Inter;
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
}

/* Guest List Start */
.cp_app_content_bx {
	background-color: #FBF8F8;
	padding: 20px 0px;
}

.cp_btn3 {
	width: 60px;
	height: 60px;
	border-radius: 8px;
	background: #1A171A;
	display: block;
	text-align: center;
	padding-top: 15%;
}

.cp_content_rt {
	background: #FBF8F8;
	height: calc(100vh - 170px);
	border-radius: 16px;
	box-shadow: 0px 8px 24px 0px #ACA6A63D;
}

.cp_content_rt .cp_card_event.card {
	box-shadow: unset;
	background: transparent;
	border: 0;
}

@media (max-width: 992px) {
	.cp_content_rt {
		height: auto;
	}
}

.cp_table {
	background-color: #fff;
	border: 2px solid #fff;
	border-radius: 18px;
	box-shadow: 0px 8px 24px 0px #ACA6A63D;
	padding-top: 2px;
}

.cp_table table thead th,
.cp_table table td {
	width: 10%;
}

.cp_table table thead th:first-child,
.cp_table table td:first-child {
	width: 70%;
	text-align: left;
}

table thead {
	font-size: 14px;
	color: #ACA6A6;
	font-weight: 600;
	line-height: 20px;
}

.cp_table table thead th {
	background: #fbf8f8;
	color: #ACA6A6;
	height: 60px;
	padding: 0 20px;
	vertical-align: middle;
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
}

.cp_table table thead th:first-child {
	border-radius: 14px 0px 0px 4px;
}

.cp_table table thead th:last-child {
	border-radius: 0 15px 4px 0px;
}

.cp_table table tbody tr td {
	/* height: 80px; */
	height: 66px;
	padding: 0 20px;
	vertical-align: middle;
	color: #2C292C;
	font-family: 'Inter', sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: -0.025em;
	border-top: 1px solid #F8F3F8;
	border-bottom: 1px solid #F8F3F8;
}

.cp_table table tbody tr {
	border: 4px solid #F8F3F8;
	border-radius: 8px;
}

/* .cp_table table tbody tr td:nth-child(2) {
	color: #8C838C;
	font-weight: 600;
} */

.cp_table table {
	border-collapse: separate;
	border-spacing: 0em 0.2em;
	padding: 0px 5px;
}

.cp_table table tbody a {
	width: 100%;
	display: contents;
}

.cp_table table tbody tr td:first-child {
	border-left: 1px solid #F8F3F8;
	border-radius: 4px 0px 0px 4px;
}

.cp_table table tbody tr td:last-child {
	border-right: 1px solid #F8F3F8;
	border-radius: 0px 4px 4px 0px;
}

.cp_table .card {
	border-color: #F8F3F8;
}

.cp_table .card .card-header {
	background: #fbf8f8;
	color: #ACA6A6;
	/* height: 60px; */
	/* padding: 0 20px; */
	vertical-align: middle;
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: -0.025em;
	border-color: #F8F3F8;
}

.cp_table .card .card-body {
	border-color: #F8F3F8;
}



.cp_table table tbody tr.progress-row {
    border: 0;
    padding: 0;
}
.cp_table table tbody tr.progress-row td {
    padding-bottom: 5px;
    height: auto;
	border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.cp_table table tbody tr:not(.progress-row) {
    border-bottom: 0;
	padding-bottom: 0;
}
.cp_table table tbody tr:not(.progress-row) td {
    border-bottom: 0;
	padding-bottom: 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.page-guest-list .cp_table .progress {
	height: 0.5rem;
}

/* Guest List End */


/* * footer start */
footer {
	background: #000;
	position: fixed;
	bottom: 0;
	width: 100%;
	right: 0;
}

footer .row.align-items-center {
	padding: 15px 0;
}

footer p {
	color: #fff;
	font: normal 500 14px/20px 'Inter';
	margin: 0;
	text-align: center;
}

/* footer End */


/* Search Box  Start*/
.cp_app_guestview_bx {
	background-color: #F8F5F5;
	padding: 10px 0
}

.cp_search {
	margin: 0 0;
	display: flex;
}

.cp_search .search {
	position: relative;
	display: inline-block;
	width: 96%;
	margin-right: 10px;
}

.cp_search .search form {
	display: flex;
	align-items: center;
	background: #fff;
	border: 1px solid #CFC8CF;
	border-radius: 8px;
	justify-content: space-between;
	padding: 0 20px;
	height: 60px;
}

.cp_search .search form i {
	position: relative;
	top: -2px;
}

.cp_search .search input {
	width: 100%;
	height: 100%;
	padding: 0 20px;
	border: 0;
	border-radius: 0;
	font-size: 16px;
	font-weight: 500;
	background-color: transparent;
	margin: 0;
	box-shadow: 0 0 0 0;
}

.cp_search .search input::placeholder {
	color: #CFC8CF;
}

.cp_search .search button {
	/* background-color: #2C292C;
    color: #fff;
    width: 120px;
    height: 52px;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    border: 0;
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    top: 4px;
    right: 4px; */
}

.cp_qr_btn {
	background-color: #2c292c;
	width: 60px;
	height: 60px;
	text-align: center;
	padding: 17px;
	border-radius: 16px;
	display: inline-block;
	border: 0;
}



.allguest_table {
	box-shadow: 0 8px 24px 0 #aca6a6;
	border-radius: 16px;
	margin-top: 10px;
	border: 2px solid #fff;
	height: calc(100vh - 231px);
	overflow: auto;
	background-color: #fff;
}


.allguest_table thead {
	background-color: #F7F6F7;
}

.allguest_table thead th {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: var(--primary-dark);
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: #F7F6F7;
	border-bottom: 2px solid #CFC8CF;
	white-space: nowrap;
	max-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.allguest_table thead th.sort-asc,
.allguest_table thead th.sort-desc {
	/* box-shadow: inset 0 -2px 0 #2c292c; */
	border-color: var(--primary-dark);
}

.allguest_table thead th i:not(.icoBefore) {
	margin-left: 10px;
}


.allguest_table table tbody td {
	font-family: Inter;
	font-size: 14px;
	color: #2C292C;
	font-weight: 500;
	margin: 0;
	vertical-align: middle;
	border-bottom: 1px solid #CFC8CF;
	vertical-align: middle;
	/* padding: 0px 20px; */
	/* height: 60px; */
	white-space: nowrap;
	max-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 42px;
}

.allguest_table thead th.large-col,
.allguest_table table tbody td.large-col {
	max-width: 200px;
}

.allguest_table table tbody td span {
	display: block;
	font-size: 12px;
	color: #8C838C;
	font-weight: 400;
}

.allguest_table table tbody td .cp_confrm_btn {
	color: #000;
	border: 1px solid #ACA6A6;
	border-radius: 40px;
	width: 140px;
	display: block;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: -0.025em;
	text-align: center;
	text-decoration: none;
	padding: 0;
	background: #fff;
}

.allguest_table table tbody td .cp_confrm_btn:hover {
	text-decoration: none;
}

.allguest_table table tbody tr.active td {
	background: #FBF8F8;
	/* color: #8C838C; */
	color: #aaaaaa;
	/* font-weight: 500; */
	font-style: italic;
}

.allguest_table table tbody tr.active td .cp_confrm_btn {
	color: #8C838C;
}

/* Search Box  End*/

/* Guest Details Canvas Start */
.checkedIn.offcanvas {
	width: 830px;
}
@media (max-width: 768px) {
	.checkedIn.offcanvas {
		width: var(--offcanvas-width);
	}	
}

.checkedIn.offcanvas .offcanvas-title {
	height: 60px;
	border-radius: 14px 14px 4px 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
	color: #fff;
	font-size: var(--header-font-size);
}

.checkedIn.offcanvas .offcanvas-header {
	background-color: var(--popup-header-bg);
	padding: 0 28px;
	height: 60px;
}

.checkedIn.offcanvas .offcanvas-header button.btn-close {
	background-color: #fff;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	padding: 0;
	background-size: 10px;
}

.checkedIn.offcanvas .offcanvas-body {
	background: #fbf8f8;
	padding: 30px;
}




.cp_main_detail.edit {}

.cp_main_detail .media {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 20px;
	background: #fff;
	border-radius: 8px;
}

@media (max-width: 768px) {
	.checkedIn.offcanvas .offcanvas-body {
		background: #FFF;
		padding: 20px 15px;
	}

	.cp_main_detail .media {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 0px;
		background: #fff;
		border-radius: 0;
	}
}

.cp_main_detail .media .media-left {
	margin-right: 30px;
}

.cp_main_detail .media .media-left .cp_im_bx {
	/* width: 200px; */
	height: 200px;
	background-color: #FBF8F8;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cp_main_detail .cp_im_bx {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cp_main_detail .cp_im_bx .imgUploadBtn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.9);
    opacity: 0.6;
}
.cp_main_detail .cp_im_bx .imgUploadBtn input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.cp_main_detail .media .media-left a.cp_confrm_btn2 {}

.cp_main_detail .media .media-body {
	flex-grow: 1;
}

.cp_main_detail .media button.btn {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #2C292C;
	background: transparent;
	width: 100%;
	border-radius: 8px;
	border-color: #aca6a652;
	height: 40px;
	line-height: 1;
	padding: 0;
}

.cp_main_detail .media button.cp_confrm_btn.btn {
	border-radius: 40px;
}

.cp_main_detail .media button.cp_print_btn.btn {
	height: 60px;
}

.cp_main_detail .media button.cp_print_btn.btn i {
	margin-right: 8px;
	position: relative;
	top: -1px;
}

.media_top {}

.media_top h5 {
	font-family: Inter;
	font-size: 30px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: -0.05em;
	/* text-align: left; */
	color: #2C292C;
}

.media_top h5 span {
	font-size: 14px;
	line-height: 30px;
	letter-spacing: -0.025em;
	display: block;
	color: #8C838C;
}

@media (max-width: 768px) {
	.media_top h5 {
		font-size: 1.3rem;
		line-height: 1.4rem;
	}

	.media_top h5 span {
		font-size: 0.9rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

/* .media_top .cp_btn_save_dlt {
    display: flex;
} */

.media_top .cp_btn_save_dlt .btn-delete.btn {
	padding: 0;
	width: auto;
	border: 0;
	margin-right: 10px;
}

.media_top .cp_btn_save_dlt .btn_edit.btn {
	width: 90px;
}

.media_top .cp_btn_save_dlt .btn_save.btn {
	width: 90px;
	background: #2C292C;
	color: #fff;
}

@media (max-width: 768px) {

	.media_top .cp_btn_save_dlt .btn_save.btn {
		width: auto;
		padding: 0 15px;
	}
}


.media_top form {}

.media-body form label.form-label {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #8C838C;
	padding: 4px 10px;
}

.media-body form input.form-control,
.media-body form .form-select {
	border: 1px solid #ACA6A6;
	height: 30px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	line-height: 30px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4D474D;
	margin-top: 4px;
	padding: 0 0.75rem;
}

.media-body form input.form-control:disabled,
.media-body form .form-select:disabled {
	background: transparent;
	border: 0;
}

.media-body form hr.dvdr {
	margin: 0;
	border-color: #CFC8CF;
}


.media-body form hr.dvdr {
	margin: 0;
	border-color: #CFC8CF;
}

.media-body button.checkIn_btn.btn {
	height: 60px !important;
	font-family: Inter;
	font-size: 20px;
	font-weight: 700;
	line-height: 40px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #e6e6e6;
	background-color: rgb(117, 116, 118);
	/* margin-top: 6px; */
	/* opacity: 1; */
}
.media-body button.checkIn_btn.btn.disabled,
.media-body button.checkIn_btn.btn:disabled {
	background-color: rgb(251, 248, 248);
	color: rgba(44, 41, 44, 0.15);
}

.media-body .checkIn_btn.btn .checkinPress {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
}

.media-body .checkIn_btn.btn .checkIn {
	display: none;
}

.media-body .checkIn_btn.btn.press {
	background-color: #2C292C !important;
}

.media-body .checkIn_btn.btn.press .checkinPress {
	display: none;
}

.media-body .checkIn_btn.btn.press .checkIn {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
}

.media-body .checkIn_btn.btn .checkinPress span.small {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	/* color: #8c838c4f; */
	color: #e6e6e6;
}

.cp_main_detail .media button.cp_print_btn.btn.hide {
	color: #2c292c26;
}

.cp_main_detail .media button.cp_print_btn.btn.hide i svg {
	opacity: 0.2;
}

.cp_main_detail .media button.cp_print_btn.btn.hide {
	color: #2c292c26;
}

.cp_main_detail .media button.cp_print_btn.btn.hide i svg {
	opacity: 0.2;
}

.cp_user_table {
	border-radius: 8px;
	padding: 20px 30px;
	background: #fff;
}

.cp_user_table table.table {}

.cp_user_table table.table thead tr th {
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	color: #8C838C;
	border-bottom: 1px solid #CFC8CF;
	padding: 10px 0;
}

.cp_user_table table.table tbody tr td {
	border-bottom: 1px solid #CFC8CF;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 30px;
	letter-spacing: -0.035em;
	color: #4D474D;
	padding: 10px 0;
}

@media (max-width: 768px) {
	.cp_user_table {
		padding: 30px 0;
	}

	.cp_user_table table.table tbody tr td {
		font-size: 0.8rem;
	}
}

/* Guest Details Canvas End */

/* Add Guest Form Canvas Start */
.addGuest.offcanvas {
	width: var(--offcanvas-width);
}

.addGuest.offcanvas .offcanvas-header {
	background: var(--popup-header-bg);
	padding: 18px 28px;
}

.addGuest.offcanvas .offcanvas-header .offcanvas-title {
	font-family: Inter;
	font-size: var(--header-font-size);
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #FFF;
}

.addGuest.offcanvas .offcanvas-header button.btn-close {
	background-color: #fff;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	padding: 0;
	background-size: 10px;
}

.addGuest.offcanvas .offcanvas-body {
	padding: 20px 28px;
}

.addGuest.offcanvas form label.form-label {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4D474D;
	display: flex;
	align-items: center;
	height: 50px;
}

.addGuest.offcanvas form input.form-control {
	border: 1px solid #ACA6A6;
	height: 50px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4D474D;
	box-shadow: 0 0 0 0;
}

.addGuest.offcanvas form input.form-control::placeholder {
	color: #CFC8CF;
}

.addGuest.offcanvas form select.form-select {
	border: 1px solid #ACA6A6;
	height: 50px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4D474D;
	box-shadow: 0 0 0 0;
}

.addGuest.offcanvas form input[type="file"] {
	content: "";
	border: 0;
}

.addGuest.offcanvas form .fileBox {
	position: relative;
	height: 50px;
	border: 1px solid #ACA6A6;
	overflow: hidden;
	border-radius: 8px;
}

.addGuest.offcanvas form .fileBox input[type="file"] {
	position: absolute;
	width: 100%;
	height: 100%;
	color: transparent;
	left: 0;
	top: 0;
	z-index: 1;
	background: transparent;
	opacity: 0;
}

.addGuest.offcanvas form .fileBox:after {
	content: "Browse";
	background: #000;
	width: 80px;
	height: 40px;
	position: absolute;
	top: 5px;
	right: 5px;
	border-radius: 4px;
	color: #fff;
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* .addGuest.offcanvas form .fileBox:before {
    content: "Will be auto-populated If left empty";
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.025em;
    text-align: left;
    color: #CFC8CF;
    height: 50px;
    line-height: 3.4;
    padding-left: 15px;
    z-index: 0;
} */
.addGuest.offcanvas form .fileBox input[type="file"]+span:before {
	content: "1 file selected";
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: -0.025em;
	text-align: left;
	color: #CFC8CF;
	height: 50px;
	line-height: 3.4;
	padding-left: 15px;
}

.addGuest.offcanvas form .fileBox input[type="file"]:invalid+span:before {
	content: "Will be auto-populated If left empty";
}

.add_guest_from .row {
	margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
	.add_guest_from {
		height: calc(100vh - 180px);
		overflow-y: auto;
		overflow-x: hidden;
	}
}

@media (max-width: 576px) {

	.add_guest_from .row {
		margin-bottom: 0.5rem;
	}

	.addGuest.offcanvas form label.form-label {
		height: auto;
	}
}

/* ADD GUEST */


/* Add Guest Form Canvas End */


/* Delete Modal Start */
.deleteModal {}

.deleteModal .modal-content {
	border-radius: 14px;
}


.deleteModal .modal-content .modal-body {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #4D474D;
	border: 0;
	padding: 30px 0;
}

.deleteModal .modal-content .modal-footer {
	border: 0;
	padding: 0 30px 30px;
}


.deleteModal .modal-content .modal-footer button.btn.btn-secondary {
	border: 1px solid #ACA6A6;
	box-shadow: 0px 12px 24px 0px #2C292C52;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #2C292C;
	background: #fff;
}

.deleteModal .modal-content .modal-footer button.btn {
	/* width: 48%; */
	width: calc((100% / 2) - 8px);
	height: 50px;
}

.deleteModal .modal-dialog {
	border-radius: 14px;
	background: transparent;
}

.deleteModal .modal-content .modal-footer button.btn.btn-danger {
	background: #D31111;
	border-color: #D31111;
	padding: 0;
}

@media (max-width: 768px) {
	.deleteModal .modal-content .modal-footer {
		padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
	}
}

/* Delete Modal End */




/* Dashboard Css Start */
.MuiDrawer-root.MuiDrawer-docked.sideDrawer {
	width: 270px;
}

.MuiDrawer-root.MuiDrawer-docked.sideDrawer .MuiDrawer-paper {
	width: 270px;
	background-color: #1C1A1C;
	display: flex;
	align-items: stretch;
	justify-content: space-between;
}

.sideDrawer a.logo {
	background-color: #1C1A1C;
	height: 80px;
	padding: 28px 25px;
	text-decoration: none;
}

.sideDrawer a.logo figure {
	margin: 0;
}

.sideDrawer .menuList {
	margin: 40px 20px;
	/* display: none; */
}

.sideDrawer .menuList ul.singleMenu {
	padding: 15px 20px;
	list-style: none;
	background-color: #2C292C;
	border-radius: 8px;
}

.sideDrawer .menuList ul.singleMenu li {}

.sideDrawer .menuList ul.singleMenu li a.linkList {
	display: flex;
	align-items: center;
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
	text-decoration: none;
}

.sideDrawer .menuList ul.singleMenu li a.linkList figure {
	margin: 0 10px 0 0;
	width: 20px;
	height: 28px;
}

.sideDrawer .menuList ul.singleMenu li a.linkList span.label {}

.sideDrawer .menuList ul.multiMenu {
	list-style: none;
	padding: 15px 20px;
	background-color: #2C292C;
	border-radius: 8px;
}

.sideDrawer .menuList ul.multiMenu li {
	border-bottom: 1px solid #4D474D;
	padding: 10px 0;
}

.sideDrawer .menuList ul.multiMenu li h4 {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #8C838C;
	margin: 0;
}

.sideDrawer .menuList ul.multiMenu li a.linkList {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
	text-decoration: none;
	position: relative;
}

.sideDrawer .menuList ul.multiMenu li a.linkList figure {
	margin: 0 10px 0 0;
}

.sideDrawer .menuList ul.multiMenu li a.linkList span.label {}

.sideDrawer .menuList ul.multiMenu li a.linkList span.separateLink {
	display: flex;
}

.sideDrawer .menuList ul.multiMenu li a.linkList button.badge {
	position: absolute;
	right: 0;
	background-color: #1C1A1C;
	border-radius: 4px;
	width: 72px;
	height: 20px;
	font-family: Inter;
	font-size: 8px;
	font-weight: 600;
	letter-spacing: -0.025em;
	text-align: center;
	color: #4D474D;
	border: 1px solid #4D474D;
	line-height: 1;
}

.sideDrawer .menuList ul.multiMenu li:last-child {
	border: 0;
}

.sideDrawer .sideDrawerBottom {
	/* position: absolute;
	bottom: 20px;
	margin: 0;
	width: 100%; */
	margin-bottom: 10px;
}

.sideDrawer .sideDrawerBottom .downloadStore {
	background-color: #2C292C;
	margin: 0 20px;
	border-radius: 8px;
	margin-bottom: 20px;
	overflow: hidden;
}

.sideDrawer .sideDrawerBottom .downloadStore figure {
	text-align: center;
	padding: 20px 0;
	margin: 0;
}

.sideDrawer .sideDrawerBottom .downloadStore .appStore {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 0px;
	background-color: #000;
	border-radius: 8px;
}

.sideDrawer .sideDrawerBottom .downloadStore .appStore figure {
	margin: 0 15px 0 0;
}

.sideDrawer .sideDrawerBottom .downloadStore .appStore .appStoreText {}

.sideDrawer .sideDrawerBottom .downloadStore .appStore .appStoreText h4 {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
	margin: 0;
}

.sideDrawer .sideDrawerBottom .downloadStore .appStore .appStoreText h2 {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
	margin: 0;
}

.sideDrawer .sideDrawerBottom .loggedBox {
	margin: 0 20px;
	background-color: #2C292C;
	border-radius: 8px;
	padding: 20px;
}

.sideDrawer .sideDrawerBottom .loggedBox h5 {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #8C838C;
}

.sideDrawer .sideDrawerBottom .loggedBox h3 {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 30px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
}

.sideDrawer .sideDrawerBottom .loggedBox hr {
	border: 1px solid #4D474D;
}

.sideDrawer .sideDrawerBottom .loggedBox button {
	background: transparent;
	border: 0;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
}

.sideDrawer .sideDrawerBottom .loggedBox button figure {
	margin: 0 10px 0 0;
}

.sideDrawer .sideDrawerBottom .loggedBox button span.label {}


/* Admin Dashboard Header */
header.topBar {
	background: #2C292C;
	height: 80px;
	padding: 14px 20px 14px 40px;
}

header .titleDv {
	height: 52px;
	display: flex;
	align-items: center;
}

header .titleDv h2 {
	margin: 0;
	font-family: Inter;
	font-size: 20px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
}

header .topBtns {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

header .topBtns button.btn-active,
header .topBtns button.btn-inactive {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	background: #CFC8CF;
	border: 0;
	padding: 10px 16px;
	height: 52px;
	margin: 0 4px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #2C292C;
	width: 142px;
}

header .topBtns button.btn-inactive {
	opacity: 0.7;
}

header .topBtns button.btn-active figure,
header .topBtns button.btn-inactive figure {
	margin: 3px 10px 0 0;
	height: 24px;
}


header .topBtns .form-switch input.form-check-input {
	background: #1A171A;
	border-color: #1A171A;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='4' cy='4' r='4' fill='%232C292C'/%3e%3c/svg%3e ");
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 12px;
	width: 28px;
	height: 18px;
	box-shadow: 0 0 0 0;
}

header .topBtns .form-switch input.form-check-input:checked {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='4' cy='4' r='4' fill='%23ACA6A6'/%3e%3c/svg%3e");
	background-position: right center;
	background-size: 12px;
	width: 28px;
	height: 18px;
	box-shadow: 0 0 0 0;
}

/* Admin Dashboard Card Start */
.createNewEvent {
	border: 1px dashed #CFC8CF;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 8px 24px 0px #ACA6A63D;
	border-radius: 16px;
	min-height: 388px;
}

.createNewEvent .content {}

.createNewEvent .content figure {
	margin: 0 0 20px;
}

.createNewEvent .content h3 {
	font-family: Inter;
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.05em;
	text-align: center;
	color: #2C292C;
}

.card.eventcard {
	border-radius: 16px;
	padding: 2px;
	display: flex;
	height: 100%;
}

.card.eventcard .card-img-top {
	border-radius: 16px;
	overflow: hidden;
	display: flex;
	align-items: flex-start;
	justify-content: center;	
}
.card.eventcard .card-img-top img {
	width: 100%;
	min-height: 100%;
}

.card.eventcard .card-body {}

.card.eventcard .card-body .statusBox {
	border: 1px solid #19BD9D;
	background-color: #E0FFF9;
	width: 70px;
	height: 24px;
	padding: 0px 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 40px;
	margin: 10px 0 12px;
}

.card.eventcard .card-body.active .statusBox {
	border: 1px solid #19BD9D;
	background-color: #E0FFF9;
}

.card.eventcard .card-body.testing .statusBox {
	border: 1px solid #4D474D;
	background-color: #FBF8F8;
}

.card.eventcard .card-body.archived .statusBox,
.card.eventcard .card-body.expired .statusBox {
	border: 1px solid #CFC8CF;
	background-color: #FFFFFF;
}

.card.eventcard .card-body .statusBox span.dot {
	background-color: #000;
	width: 6px;
	height: 6px;
	display: block;
	border-radius: 50%;
	margin: 0 4px 0 0;
}

.card.eventcard .card-body.active .statusBox span.dot {
	background-color: #19BD9D;
}

.card.eventcard .card-body.testing .statusBox span.dot {
	background-color: #4D474D;
}

.card.eventcard .card-body.archived .statusBox span.dot,
.card.eventcard .card-body.expired .statusBox span.dot {
	background-color: #CFC8CF;
}

.card.eventcard .card-body .statusBox span.label {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #000;
}

.card.eventcard .card-body .statusBox.active span.label {
	color: #19BD9D;
}

.card.eventcard .card-body .statusBox.testing span.label {
	color: #4D474D;
}

.card.eventcard .card-body .statusBox.archived span.label,
.card.eventcard .card-body .statusBox.expired span.label {
	color: #CFC8CF;
}


.card.eventcard .card-body .card-title {
	font-family: Inter;
	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.05em;
	text-align: left;
	color: #2C292C;
	margin: 0 0 15px;
}

.card.eventcard .card-body.archived .card-title,
.card.eventcard .card-body.expired .card-title {
	color: #8C838C;
}

.card.eventcard .card-body p.card-text {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #8C838C;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.card.eventcard .card-body p.card-text i {
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
}

.card.eventcard .card-body .btnGroups {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.card.eventcard .card-body .btnGroups button.btn-upload,
.card.eventcard .card-body .btnGroups a.btn-upload {
	height: 40px;
	background-color: #2C292C;
	border-radius: 8px;
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
	padding: 0 30px;
	border: 0;
	box-shadow: 0px 12px 24px 0px #2C292C52;
	text-decoration: none;
}

.card.eventcard .card-body .btnGroups a.btn-upload i {
	margin-right: 10px;
}

.card.eventcard .card-body.archived .btnGroups button.btn-upload,
.card.eventcard .card-body.archived .btnGroups a.btn-upload,
.card.eventcard .card-body.expired .btnGroups button.btn-upload,
.card.eventcard .card-body.expired .btnGroups a.btn-upload {
	background-color: #CFC8CF;
}

.card.eventcard .card-body .btnGroups .dropdown {}

.card.eventcard .card-body .btnGroups .dropdown button {
	padding: 6px 11px;
	width: 40px;
	height: 40px;
	border: 1px solid #2C292C;
	background-color: #fff;
	box-shadow: 0px 12px 24px 0px #2C292C52;
}

.card.eventcard .card-body .btnGroups .dropdown button::after {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='4' height='14' viewBox='0 0 4 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3.75 1.75C3.75 2.7165 2.9665 3.5 2 3.5C1.0335 3.5 0.25 2.7165 0.25 1.75C0.25 0.783502 1.0335 0 2 0C2.9665 0 3.75 0.783502 3.75 1.75Z' fill='%232C292C'/%3e%3cpath d='M3.75 7C3.75 7.9665 2.9665 8.75 2 8.75C1.0335 8.75 0.25 7.9665 0.25 7C0.25 6.0335 1.0335 5.25 2 5.25C2.9665 5.25 3.75 6.0335 3.75 7Z' fill='%232C292C'/%3e%3cpath d='M3.75 12.25C3.75 13.2165 2.9665 14 2 14C1.0335 14 0.25 13.2165 0.25 12.25C0.25 11.2835 1.0335 10.5 2 10.5C2.9665 10.5 3.75 11.2835 3.75 12.25Z' fill='%232C292C'/%3e%3c/svg%3e ");
	border: 0;
	background-size: 6px;
	background-repeat: no-repeat;
	background-position-y: 2px;
	width: 6px;
	height: 26px;
}

.card.eventcard .card-body .btnGroups button.btn-upload i {
	position: relative;
	top: -1px;
	margin-right: 10px;
}


.card.eventcard .card-body .btnGroups .dropdown .dropdown-menu {
	width: 200px;
	box-shadow: 0px 8px 24px 0px #ACA6A63D;
	border: 0;
	margin-top: 14px;
}

.card.eventcard .card-body .btnGroups .dropdown .dropdown-menu a.dropdown-item {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4D474D;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.card.eventcard .card-body .btnGroups .dropdown .dropdown-menu a.dropdown-item i {
	width: 20px;
	height: 20px;
	margin: -2px 5px 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.card.eventcard .card-body .btnGroups .dropdown .dropdown-menu:before {
	content: "";
	width: 14px;
	height: 14px;
	position: absolute;
	right: 12px;
	top: -6px;
	background: #fff;
	transform: rotate(45deg);
	border-radius: 2px;
	box-shadow: -9px -10px 14px 1px #ACA6A63D;
}


/* Create Event Modal Start */
.createNewAccountModal {
	top: 80px;
}

.createNewAccountModal .modal-dialog {
	/* width: 540px; */
}

.createNewAccountModal .modal-dialog .modal-content {}

.modal-dialog .modal-content .modal-header {
	background-color: var(--popup-header-bg);
	padding: 18px 20px 18px 30px;
}

.modal-dialog .modal-content .modal-header .modal-title {
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #FFF;
}

.modal-dialog .modal-content .modal-header button.btn-close {
	width: 40px;
	height: 40px;
	background-color: #fff;
	opacity: 0.6;
	border-radius: 50%;
	padding: 0;
	background-size: 10px;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body {
	padding: 30px;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body label.form-label {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4d474d;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body input.form-control {
	border: 1px solid #ACA6A6;
	height: 50px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	/* color: #CFC8CF; */
	box-shadow: 0 0 0 0;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body select.form-select {
	border: 1px solid #ACA6A6;
	height: 50px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	/* color: #CFC8CF; */
	box-shadow: 0 0 0 0;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body .uploadEventFile {
	width: 100%;
	height: 50px;
	border: 1px solid #ACA6A6;
	border-radius: 8px;
	overflow: hidden;
	position: relative;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body .uploadEventFile input.form-control {
	border: 0;
	width: 100%;
	left: 0;
	position: absolute;
	height: 100%;
	background-color: transparent;
	z-index: 1;
	opacity: 0;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body .uploadEventFile span.choose {
	background: #2C292C;
	position: absolute;
	right: 5px;
	top: 5px;
	width: 80px;
	height: 40px;
	border-radius: 8px;
	color: #fff;
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 0;
	box-shadow: 0px 12px 24px 0px #2C292C52;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body p.note {
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #CFC8CF;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body .uploadEventFile span.placeholderLabel {
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	/* color: #CFC8CF; */
	position: absolute;
	top: 15px;
	left: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 70%;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body input.form-control::placeholder {
	color: #CFC8CF;
}


.createNewAccountModal .modal-dialog .modal-content .modal-body .inBox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 10px;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body .inBox .btnGroup {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-left: 10px;
	width: 80px;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body .inBox .btnGroup button.btn {
	box-shadow: 0px 12px 24px 0px #2C292C52;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #2C292C;
	border: 0;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body .inBox .btnGroup button.btn svg path {
	stroke: #fff;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body .inBox .btnGroup button.btn.btn-decrement {
	background-color: #CFC8CF;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body p {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 30px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #4D474D;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body h4 {
	font-family: Inter;
	font-size: 20px;
	font-weight: 600;
	line-height: 32px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #4D474D;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body .actionBtns {
	display: flex;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body .actionBtns button.btn-cancel {
	border: 1px solid #2C292C;
	width: 140px;
	height: 50px;
	background: #fff;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #2C292C;
	margin-right: 10px;
}

.createNewAccountModal .modal-dialog .modal-content .modal-body .actionBtns button.btn-steps {
	width: 100%;
	height: 50px;
	box-shadow: 0px 12px 24px 0px #2C292C52;
	background-color: #2C292C;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
	border-color: #2C292C;
}

/* Admin SideBar EventBox */
.sideDrawer .menuList .eventBox {
	background-color: #2C292C;
	padding: 15px 20px;
	border-radius: 8px;
}

.sideDrawer .menuList .eventBox .eventTitleBar {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.sideDrawer .menuList .eventBox .eventTitleBar h4 {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #8C838C;
	margin: 0;
}

.sideDrawer .menuList .eventBox .eventTitleBar span.badge {
	height: 24px;
	display: flex;
	align-items: center;
	border-radius: 40px;
}

.sideDrawer .menuList .eventBox .eventTitleBar span.badge.active {
	border: 1px solid #19BD9D;
	background: #E0FFF9;
}

.sideDrawer .menuList .eventBox .eventTitleBar span.badge.active .dot {
	background: aliceblue;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: #19BD9D;
	margin-right: 5px;
}

.sideDrawer .menuList .eventBox .eventTitleBar span.badge span.label {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #000;
}

.sideDrawer .menuList .eventBox .eventTitleBar span.badge.active span.label {
	color: #19BD9D;
}

.sideDrawer .menuList .eventBox .eventTitleBar span.badge.testing {
	border: 1px solid #4D474D;
	background: #F8F8F8;
}

.sideDrawer .menuList .eventBox .eventTitleBar span.badge.testing .dot {
	background: aliceblue;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: #4D474D;
	margin-right: 5px;
}

.sideDrawer .menuList .eventBox .eventTitleBar span.badge.testing span.label {
	color: #4D474D;
}

.sideDrawer .menuList .eventBox .eventTitleBar span.badge.archived,
.sideDrawer .menuList .eventBox .eventTitleBar span.badge.expired {
	border: 1px solid #CFC8CF;
	background: #FFFFFF;
}

.sideDrawer .menuList .eventBox .eventTitleBar span.badge.archived .dot,
.sideDrawer .menuList .eventBox .eventTitleBar span.badge.expired .dot {
	background: aliceblue;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: #CFC8CF;
	margin-right: 5px;
}

.sideDrawer .menuList .eventBox .eventTitleBar span.badge.archived span.label,
.sideDrawer .menuList .eventBox .eventTitleBar span.badge.expired span.label {
	color: #CFC8CF;
}


.sideDrawer .menuList .eventBox hr.dvdr {
	background: #4D474D;
	margin: 10px 0 15px;
}

.sideDrawer .menuList .eventBox .ceremonyBox {
	margin-bottom: 20px;
}

.sideDrawer .menuList .eventBox .ceremonyBox .linkItem {
	text-decoration: none;
	margin-bottom: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.sideDrawer .menuList .eventBox .ceremonyBox .linkItem span.linkBox {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	overflow: hidden;
}

.sideDrawer .menuList .eventBox .ceremonyBox .linkItem span.linkBox i.ico {
	margin-right: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
}

.sideDrawer .menuList .eventBox .ceremonyBox .linkItem span.linkBox span.label {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #ffffff;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sideDrawer .menuList .eventBox .ceremonyBox .linkItem button.eventDetails.btn.btn-primary {
	padding: 0;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	border-color: #fff;
	box-shadow: 0 0 0 0;
}

.sideDrawer .menuList .eventBox .btnGroups {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.sideDrawer .menuList .eventBox .btnGroups .bn-theme-primary.btn {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
	background-color: #1A171A;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 28px;
	padding: 5px 10px;
	box-shadow: 0 0 0 0;
}

.sideDrawer .menuList .eventBox .btnGroups .bn-theme-primary.btn i.ico {
	margin-right: 5px;
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sideDrawer .menuList .eventBox .btnGroups .bn-theme-primary.btn span.label {}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion {}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item {
	border-radius: 0;
	background-color: transparent;
	border: 0;
}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item h2.accordion-header {
	padding: 0;
}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item h2.accordion-header button.accordion-button {
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	padding: 5px 0 10px;
	background-color: transparent;
	color: #CFC8CF;
	box-shadow: 0 0 0px 0;
	border-bottom: 1px solid #4D474D;
	border-radius: 0;
}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item h2.accordion-header button.accordion-button::after {
	background-size: 10px;
	background-repeat: no-repeat;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.5 1L4.5 4L7.5 1' stroke='white' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round'/%3e%3c/svg%3e");
	background-position: center;
	transform: rotate(180deg);
}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item h2.accordion-header button.accordion-button:not(.collapsed)::after {
	transform: rotate(0deg);
}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item .accordion-collapse {}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item .accordion-collapse .accordion-body {
	padding: 10px 0 0;
}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item .accordion-collapse .accordion-body ul {
	padding: 0;
	margin: 0;
}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item .accordion-collapse .accordion-body ul li {}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item .accordion-collapse .accordion-body ul li .itemLink {
	text-decoration: none;
	margin: 10px 0;
	display: block;
}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item .accordion-collapse .accordion-body ul li .itemLink a {
	text-decoration: none;
	color: #fff;
}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item .accordion-collapse .accordion-body ul li .itemLink .linkBox {
	font-family: Inter;
	font-size: 10px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
}


.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item .accordion-collapse .accordion-body ul li .itemLink .linkBox button.btn-theme-white.btn {
	font-family: Inter;
	font-size: 8px;
	font-weight: 600;
	line-height: 14px;
	letter-spacing: -0.025em;
	text-align: center;
	background-color: #fff;
}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item .accordion-collapse .accordion-body ul li .itemLink .linkBox button.btn-theme-secondary.btn {
	font-family: Inter;
	font-size: 8px;
	font-weight: 600;
	line-height: 14px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #4D474D;
	background-color: #1C1A1C;
	border: 1px solid #4D474D;
}


.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item .accordion-collapse .accordion-body ul li .itemLink .linkBox input.form-check-input {
	background: #1A171A;
	border-color: #1A171A;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='4' cy='4' r='4' fill='%232C292C'/%3e%3c/svg%3e ");
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 12px;
	width: 28px;
	height: 18px;
	box-shadow: 0 0 0 0;
}

.sideDrawer .menuList .eventBox .ceremonyBox .accordion .accordion-item .accordion-collapse .accordion-body ul li .itemLink .linkBox input.form-check-input:checked {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='4' cy='4' r='4' fill='%23ACA6A6'/%3e%3c/svg%3e");
	background-position: right center;
	background-size: 12px;
	width: 28px;
	height: 18px;
	box-shadow: 0 0 0 0;
}


/* Upload Guest */

.uploadGuest {}

.uploadGuest .stepsBtn {
	background-color: #fff;
	padding: 3px;
	border-radius: 4px;
}

.uploadGuest .stepsBtn button.btn-uploadGuest.btn {
	background-color: #FBF8F8;
	width: 100%;
	height: 60px;
	line-height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: -0.025em;
	text-align: left;
	color: #CFC8CF;
	pointer-events: none;
}
.uploadGuest .stepsBtn button.btn-uploadGuest.btn.active {
	color: #333;
    box-shadow: 0px 3px 15px 0px rgba(44, 41, 44, 0.25);
    background-color: #CFC8CF;
    border-color: #CFC8CF;
}

.uploadGuest .selectFile {}

.uploadGuest .selectFile .topBar {}

.uploadGuest .selectFile .topBar .selectList {
	margin: 10px 0;
	background: #fff;
	padding: 5px 20px;
	border-radius: 8px;
}

.uploadGuest .selectFile .topBar .selectList .selectListDD {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.uploadGuest .selectFile .topBar .selectList .selectListDD span.label {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4D474D;
	text-wrap: nowrap;
	margin-right: 40px;
}

.uploadGuest .selectFile .topBar .selectList .selectListDD select.form-select {
	/* width: 220px; */
	border: 1px solid #ACA6A6;
	height: 50px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #2C292C;
	box-shadow: none;
}

.uploadGuest .selectFile .topBar .selectList .downloadTemplate {
	display: flex;
	justify-content: end;
}

.uploadGuest .selectFile .topBar .selectList .downloadTemplate button.btn-theme-primary.btn {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	background-color: #2C292C;
	color: #fff;
	border-radius: 8px;
	height: 50px;
	padding: 0 20px;
	box-shadow: 0px 12px 24px 0px #2C292C52;
}

.uploadGuest .selectFile .dragNdrop {
	border: 1px dashed #ccc;
	position: relative;
	overflow: hidden;
	padding: 50px 0;
	border-radius: 8px;
}

.uploadGuest .selectFile .dragNdrop:before {
	content: "";
	background-color: #fff;
	width: calc(100% + 14px);
	height: calc(100% + 14px);
	display: block;
	position: absolute;
	top: -7px;
	left: -7px;
}

.uploadGuest .selectFile .dragNdrop .browseTxt {
	position: relative;
}

.uploadGuest .selectFile .dragNdrop .browseTxt .dndLabels {}

.uploadGuest .selectFile .dragNdrop .browseTxt .dndLabels figure {
	text-align: center;
}

.uploadGuest .selectFile .dragNdrop .browseTxt .dndLabels h5 {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #2C292C;
}

.uploadGuest .selectFile .dragNdrop .browseTxt .dndLabels h6 {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #CFC8CF;
}

.uploadGuest .selectFile .dragNdrop .browseTxt .dndLabels button.btn-theme-primary.btn {
	background-color: #2C292C;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
	height: 50px;
	margin: 0 auto 15px;
	display: block;
	padding: 0 30px;
	border-radius: 8px;
	box-shadow: 0px 12px 24px 0px #2C292C52;
}

.uploadGuest .selectFile .dragNdrop .browseTxt .dndLabels .instructions {
	position: relative;
	border: 1px solid #CFC8CF;
	border-radius: 8px;
	padding: 30px;
	background-color: #cfc8cf26;
}

.uploadGuest .selectFile .dragNdrop .browseTxt .dndLabels .instructions .leftBgTop {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
}

.uploadGuest .selectFile .dragNdrop .browseTxt .dndLabels .instructions p {
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #8C838C;
}

.uploadGuest .selectFile .dragNdrop .browseTxt .dndLabels .instructions ul {
	padding: 0 0 0 15px;
}

.uploadGuest .selectFile .dragNdrop .browseTxt .dndLabels .instructions ul li {
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #8C838C;
}

.uploadGuest .selectFile .dragNdrop input.uploadFile {
	position: absolute;
	top: 0;
	height: 160%;
	left: -30%;
	width: 160%;
	z-index: 1;
	background: transparent;
	box-shadow: 0 0 0 0;
}

/* Pair header */
.uploadGuest .selectFile .topBar .pairHeader {
	background-color: #fff;
	padding: 25px;
	margin: 10px 0;
	border-radius: 8px;
}

.uploadGuest .selectFile .topBar .pairHeader h3 {
	margin: 0;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4D474D;
}

.uploadGuest .selectFile .guestFields {
	background-color: #fff;
	border-radius: 8px;
	padding: 25px;
}

.uploadGuest .selectFile .guestFields .customCol {
	display: flex;
	/* justify-content: space-between; */
	margin-bottom: 45px;
}

.uploadGuest .selectFile .guestFields .customCol .detectCol {
	width: 270px;
}

.uploadGuest .selectFile .guestFields .customCol .arrow {
	margin: 0 30px;
}

.uploadGuest .selectFile .guestFields .customCol .guestFieldCol {
	width: 320px;
	margin-right: 40px;
	min-height: 148px;
}

.uploadGuest .selectFile .guestFields .customCol .pairingCol {
	width: 300px;
}

.uploadGuest .selectFile .guestFields .customCol .guestFieldCol.isPaired .colBox,
.uploadGuest .selectFile .guestFields .customCol .pairingCol.isPaired .colBox {
	background-color: rgba(25, 135, 84, 0.15);
}

.uploadGuest .selectFile .guestFields .customCol .title {
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4D474D;
}

.uploadGuest .selectFile .guestFields .customCol .detectCol table {
	border: 1px solid #CFC8CF;
	margin: 0;
	height: 100%;
}

.uploadGuest .selectFile .guestFields .customCol .detectCol table thead {}

.uploadGuest .selectFile .guestFields .customCol .detectCol table thead tr {}

.uploadGuest .selectFile .guestFields .customCol .detectCol table thead tr th {
	border-right: 1px solid #CFC8CF;
	background-color: #cfc8cf1f;
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #4d474d;
}

.uploadGuest .selectFile .guestFields .customCol .detectCol table tbody {}

.uploadGuest .selectFile .guestFields .customCol .detectCol table tbody tr {}

.uploadGuest .selectFile .guestFields .customCol .detectCol table tbody tr td {
	border-right: 1px solid #CFC8CF;
	font-family: Inter;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #8C838C;
}

.uploadGuest .selectFile .guestFields .customCol .arrow figure {
	margin: 0;
	display: flex;
	align-items: center;
	height: 100%;
}

.uploadGuest .selectFile .guestFields .customCol .guestFieldCol h3.title {}

.uploadGuest .selectFile .guestFields .customCol .colBox {
	background-color: #FBF8F8;
	padding: 20px;
	height: 100%;
	border-radius: 8px;
}

.uploadGuest .selectFile .guestFields .customCol .guestFieldCol .colBox select.form-select {
	height: 50px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #2C292C;
	border: 1px solid #ACA6A6;
	box-shadow: 0 0 0 0;
}

.uploadGuest .selectFile .guestFields .customCol .guestFieldCol .colBox input.form-control {
	border: 1px solid #ACA6A6;
	height: 50px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	box-shadow: 0 0 0 0;
}
.uploadGuest .selectFile .guestFields .customCol .guestFieldCol .colBox input.form-control::placeholder {
	color: #CFC8CF;
}

.uploadGuest .selectFile .guestFields .customCol .detectCol table tbody tr td:first-child {
	background-color: #cfc8cf1f;
	color: #4d474d;
}

.uploadGuest .selectFile .guestFields .customCol .detectCol table tbody tr:first-child td {
	background-color: #cfc8cf1f;
	color: #4D474D;
}

.uploadGuest .selectFile .guestFields .customCol .pairingCol .paired {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.uploadGuest .selectFile .guestFields .customCol .pairingCol .paired figure {
	margin: 0 10px 0 0;
}


.uploadGuest .selectFile .guestFields .customCol .pairingCol .paired figure.active svg path {
	stroke: #26DEBD;
}


.uploadGuest .selectFile .guestFields .actionBtns {
	display: flex;
	justify-content: end;
	align-items: center;
}

.uploadGuest .selectFile .guestFields .actionBtns .btnDv {
	display: flex;
	align-items: center;
}

.uploadGuest .selectFile .guestFields .actionBtns .btnDv button.btn.btn-theme-secondary {
	box-shadow: 0px 12px 24px 0px #2C292C52;
	background-color: #CFC8CF;
	border-color: #CFC8CF;
	height: 50px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4D474D;
	border-radius: 8px;
	margin-right: 10px;
	padding: 0 25px;
}

.uploadGuest .selectFile .guestFields .actionBtns .btnDv button.btn.btn-theme-primary {
	box-shadow: 0px 12px 24px 0px #2C292C52;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: 150px;
	background-color: #2C292C;
	border-color: #2C292C;
}


.uploadGuest .selectFile .guestFields .actionBtns {
	display: flex;
	justify-content: end;
	align-items: center;
}

.uploadGuest .selectFile .guestFields .actionBtns .btnDv {
	display: flex;
	align-items: center;
}

.uploadGuest .selectFile .guestFields .actionBtns .btnDv button.btn.btn-theme-secondary {
	box-shadow: 0px 12px 24px 0px #2C292C52;
	background-color: #CFC8CF;
	border-color: #CFC8CF;
	height: 50px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4D474D;
	border-radius: 8px;
	margin-right: 10px;
	padding: 0 25px;
}

.uploadGuest .selectFile .guestFields .actionBtns .btnDv button.btn.btn-theme-primary {
	box-shadow: 0px 12px 24px 0px #2C292C52;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: 150px;
	background-color: #2C292C;
	border-color: #2C292C;
}

.uploadGuest .selectFile .succesfully {
	border: 1px dashed #ccc;
	position: relative;
	overflow: hidden;
	padding: 50px 0;
	border-radius: 8px;
	height: 76vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.uploadGuest .selectFile .succesfully:before {
	content: "";
	background-color: #fff;
	width: calc(100% + 14px);
	height: calc(100% + 14px);
	display: block;
	position: absolute;
	top: -7px;
	left: -7px;
}

.uploadGuest .selectFile .succesfully .completed {}

.uploadGuest .selectFile .succesfully .completed figure {
	text-align: center;
	margin: 0 0 50px;
}

.uploadGuest .selectFile .succesfully .completed h3 {
	font-family: Inter;
	font-size: 24px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #8C838C;
	margin: 0 0 10px;
}

.uploadGuest .selectFile .succesfully .completed h6 {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #8C838C;
	margin: 0 0 30px;
}

.uploadGuest .selectFile .succesfully .completed button.btn-theme-primary.btn {
	background: #2C292C;
	border-radius: 8px;
	height: 50px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
	display: table;
	margin: 0 auto 20px;
	padding: 0 20px;
}

.uploadGuest .selectFile .succesfully .completed button.btn-theme-text.btn {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #2C292C;
	display: table;
	margin: 0 auto;
	box-shadow: 0 0 0 0;
}

.uploadGuest .selectFile .succesfully .completed button.btn-theme-text.btn:active {
	border: 0;
}

/* AK- Guest list admin Start  */
.cp_table.cp_wdth table thead th:first-child {
	font-size: 14px;
}

.cp_table.cp_wdth table thead th:first-child,
.cp_table table td:first-child {
	width: 14%;
}

.cp_table.cp_wdth table thead th {
	font-size: 12px;
}

.cp_table.cp_wdth table thead th:first-child,
.cp_table table td {
	width: 13%;
}

.cp_table.cp_wdth table td,
.cp_table.cp_wdth table thead th {
	width: 2%;
}

.cp_table.cp_wdth table thead th:last-child,
.cp_table table td:last-child {
	width: 3%;
}


#admin-guest-list-table .udd_btn a svg {
	width: 60px;
	margin: 0 -19px 0 0;
}

#admin-guest-list-table .udd_btn {
	text-align: left;
}

#admin-guest-list-table button.edit-btn {
	display: none;
	align-items: center;
	justify-content: center;
	width: 33px;
	height: 33px;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}
#admin-guest-list-table tr:hover button.edit-btn {
	display: flex;
}

.cp_btn_pop {
	width: 100%;
	height: 50px;
	box-shadow: 0px 12px 24px 0px #2C292C52;
	background-color: #2C292C;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
	border-color: #2C292C;
	margin-bottom: 20px;
}

.cp_btn_pop:hover {
	background-color: #2C292C;
}

.cp_delete_btn {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 235px;
	padding: 0 20px;
	color: #fff;
	box-shadow: 0 12 24 0 #2C292C52 !important;
	border: 1px solid #ACA6A6 !important;
	background-color: #D31111 !important;
	border-radius: 8px;
	font-size: 16px;
	font-weight: 600;
}

/* AK- Guest list admin End  */

/* AK- Accounts Admin Start */
.cp_text_bx ul li {
	border-bottom: 1px solid rgb(207 200 207 / 36%);
}

.cp_text_bx ul {
	list-style: none;
	padding: 0;
}

.cp_text_bx ul li p {
	width: 180px;
	display: inline-block;
	margin: 10px 0;
	color: #8C838C;
}

.cp_text_bx ul li p span {
	width: 180px;
}

.cp_text_bx ul input {
	width: calc(100% - 185px);
	border: 1px solid #ACA6A6;
	border-radius: 4px;
	height: 39px;
	padding-left: 10px;
	padding-right: 10px;
}

.cp_value li {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.cp_value li.no-border {
	border: 0;
}
.cp_value li input:disabled {
	border: none;
	background-color: #fff;
}

.cp-btn2 {
	width: 100%;
	height: 60px;
	display: flex;
	justify-content: space-between;
	border: 1px solid #2C292C;
	align-items: center;
	padding: 0 30px;
	border-radius: 8px;
	margin-top: 30px;
}

.cp_tabs_contentbx {
	background: #fff;
	padding: 30px;
	border-radius: 8px;
	margin-bottom: 20px;
}

.cp_head_btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.head2 {
	font-size: 16px;
	color: #2C292C;
	font-weight: 600;
	line-height: 20px;
	margin-bottom: 20px;
	display: inline-block;
}

.update_btn {
	font-size: 16px;
	color: #fff;
	background: #2C292C;
	padding: 5px 14px 5px 14px;
	border-radius: 4px;
	font-size: 12px;
	line-height: 20px;
	font-weight: 600;
	text-decoration: none;
	display: inline-block;
	text-align: center;
	transition: all 0.4s ease-in;
	border: 1px solid transparent;
	box-shadow: 0 12px 24px 0 #2C292C52;
	Z-INDEX: 100;
}

.update_btn:hover {
	background: #fff;
	color: #2c292c;
	text-decoration: none;
	border: 1px solid #333;
}

.cp_text_bx ul li p:last-child {
	width: 100%;
}

.cp_tabselect {
	width: 210px;
}

.cp_tabs_contentbx p {
	color: #8C838C;
}

.cp_tabs_contentbx li:last-child {
	border: none;
	width: calc(100% - 400px);
}

.cp_tab.nav {
	background: #fff;
	padding: 8px 6px 8px;
	border-radius: 4px;
}

.cp_table.cp_invoice table tbody tr td:nth-child(2) {
	width: 7%;
}

.cp_table.cp_invoice table tbody tr td:nth-child(3) {
	width: 5%;
}

.cp_tab .nav-link.active {
	background: #FBF8F8;
	border: none;
}

.cp_tab .nav-link {
	width: 190px;
	height: 52px;
	margin-right: 10px;
	color: #8C838C;
	background: #FBF8F8;
	position: relative;
	font-weight: 600;
}

.cp_tab .nav-link.active:after {
	width: 180px;
	height: 2px;
	background: #CFC8CF;
	content: '';
	position: absolute;
	left: 0;
	bottom: 6px;
	right: 0;
	margin: 0 auto;
	border-radius: 4px;
}

.cp_table table tbody a.cp_btn_download {
	background: #2C292C;
	color: #fff;
	width: 136px;
	display: flex;
	text-align: center;
	text-decoration: none;
	align-items: center;
	justify-content: center;
	gap: 20px;
	border-radius: 4px;
	font-size: 16px;
}

.cp_table.cp_wdth table thead th:last-child,
.cp_table table td:last-child {
	width: 3%;
}

.cp_table.cp_wdth.cp_tab_h table thead th:first-child {
	width: 22%;
}

.cp_btn-position {
	position: relative;
}

.cp_btn-position .update_btn.pass {
	position: absolute;
	right: -136px;
	top: 15px;
}

.cp_text_bx ul input li.cp_btn-position input {
	width: calc(100% - 400px);
}

.cp_tabs_contentbx li.cp_btn-position {
	width: calc(100% - 136px);
}

.cp_banner {
	display: flex;
	align-items: center;
	height: 140px;
	background: #fff;
	padding: 0px 30px;
	margin-top: 40px;
}

.cp_banner p {
	max-width: 458px;
	margin: 0;
	line-height: 28px;
	color: #4D474D;
	font-size: 16px;
	font-weight: 600;
}

.cp_banner {
	background: url(../images/setupbadge_banner.jpg) no-repeat;
	background-position: 90% 0;
	background-color: #fff;
	border-radius: 18px;
}

/* AK- Accounts Admin End */

/* AK- Setup Badge Print Admin Start */
.cp_badge_print_table {
	margin-top: 25px;
	background: #fff;
	border-radius: 18px;
	position: relative;
}

.cp_badge_print_table table {
	border-collapse: separate;
	border-spacing: 0em 0.2em;
	padding: 0px 5px;
	padding: 3px 6px;
}

/* .cp_badge_print_table table thead th:first-child {border-radius: 14px 0px 0px 4px; width: 170px;}
.cp_badge_print_table table thead th:last-child {border-radius: 0 15px 4px 0px;} */
.cp_badge_print_table.cp_table table thead th {
	background: #cfc8cf54;
	color: #ACA6A6;
	height: 60px;
	padding: 0 20px;
	vertical-align: middle;
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	border-radius: 14px 15px 0 0;
}

.cp_badge_print_table table tbody tr td p {
	margin: 0 0 0 12px;
}

.cp_badge_print_table table tbody tr td {
	vertical-align: middle;
}

.cp_badge_print_table table tbody tr td button {
	margin-right: 7px;
	display: inline;
	border: 0;
	padding: 0;
}

.cp_bottom_box {
	position: absolute;
	bottom: 40px;
	display: flex;
	justify-content: end;
	width: 100%;
	right: 40px;
	gap: 10px;
}

.cp_light_btn {
	border: 1px solid #4D474D;
	color: #4D474D;
	font-size: 16px;
	padding: 14px 20px;
	border-radius: 8px;
	text-decoration: none;
	transition: all 0.4s ease-in-out;
}

.cp_light_btn:hover {
	background-color: #2C292C;
	color: #fff;
}

.cp_blk_btn {
	border: 1px solid transparent;
	color: #fff;
	font-size: 16px;
	padding: 14px 20px;
	border-radius: 8px;
	text-decoration: none;
	background: var(--primary-dark);
	box-shadow: 0 12px 24px 0 rgb(44 41 44 / 36%);
	transition: all 0.4s ease-in-out;
	border-color: var(--primary-dark);
}

.cp_blk_btn.all-changes-save {
	background-color: #fff;
	color: var(--primary-dark);
	box-shadow: none;
}

/* AK- Setup Badge Print Admin End */

/* Download Guest Page */
.cp_table .eventDv {
	border-radius: 15px 15px 0px 0px;
	margin: 3px;
	border: 1px solid #F8F3F8;
	background-color: #f8f3f863;
	padding: 18px 24px;
}

.cp_table .eventDv .listDropdown {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cp_table .eventDv .listDropdown label.event-select {
	text-wrap: nowrap;
	margin-right: 30px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: var(--primary-dark);
}

.cp_table .eventDv .listDropdown select.form-select {
	border: 1px solid #ACA6A6;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: var(--primary-dark);
	height: 44px;
}

.cp_table .eventBody {
	height: calc(100% - 162px);
	padding: 0 30px;
}

.cp_table .eventFooter {
	display: flex;
	align-items: center;
	justify-content: end;
	padding: 0 30px;
}

.cp_table .eventFooter .actionBtns {}

.cp_table .eventFooter .actionBtns button.btn-theme-white.btn {
	border: 1px solid #4D474D;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4D474D;
	padding: 0 20px;
	height: 50px;
	margin-right: 10px;
}

.cp_table .eventFooter .actionBtns a.btn.btn-theme-primary {
	box-shadow: 0px 12px 24px 0px #2C292C52;
	background-color: #2C292C;
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: -0.025em;
	text-align: left;
	color: #fff;
	height: 50px;
	line-height: 2.3;
}

/* Data Feilds  */
.subHeader {
	background: #fff;
	border-radius: 16px;
	padding: 20px 30px 0;
	margin-bottom: 20px;
	overflow: hidden;
}

.subHeader h4 {
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4D474D;
	margin: 30px 0 0;
}

.subHeader figure {
	margin: 0;
	text-align: center;
}

.subHeader figure img {
	width: 100%;
	box-shadow: 0px 20px 40px 0px #333333ab;
	border-radius: 24px 24px 0 0;
}

.cp_table.dataFields {
	overflow: auto;
}

.cp_table.dataFields table {}

.cp_table.dataFields table thead {}

.cp_table.dataFields table thead tr {}

.cp_table.dataFields table thead tr th {
	padding: 0 10px;
}

.cp_table.dataFields .headtitle {
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #8C838C;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cp_table.dataFields .headtitle button.btn.btn-primary {
	background-color: #2C292C;
	border: 1px solid #2C292C;
}

.cp_table.dataFields table tbody {}

.cp_table.dataFields table tbody tr.dragging td {
	background: #fffce4;
}

.cp_table.dataFields table tbody tr td {
	padding: 0 10px;
}

.cp_table.dataFields table tbody tr td .tbodyText {}


.cp_table.dataFields table tbody tr td .tbodyText .inputWithBtn {
	position: relative;
}

.cp_table.dataFields table tbody tr td .tbodyText .inputWithBtn input.form-control {
	padding-right: 50px;
}

.cp_table.dataFields table tbody tr td .tbodyText .inputWithBtn button.btn-theme-primary.btn {
	position: absolute;
	right: 15px;
	top: 11px;
	background-color: #2C292C;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 8px 16px 0px #00000066;
	padding: 0;
	z-index: 1;
}

.cp_table.dataFields table tbody tr td .tbodyText .inputWithBtn button.btn-theme-primary.btn i.ico {
	position: relative;
	top: -1px;
}

.cp_table.dataFields table tbody tr td .tbodyText input.form-control {
	border: 1px solid #ACA6A6;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	height: 44px;
	color: #4D474D;
	box-shadow: 0 0 0 0;
}

.cp_table.dataFields table tbody tr td .tbodyText select.form-select {
	border: 1px solid #8C838C;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #2C292C;
	height: 44px;
	box-shadow: 0 0 0 0;
}

.cp_table.dataFields table tbody tr td .tbodyText .actions {
	display: flex;
	align-items: center;
	justify-content: end;
}

.cp_table.dataFields table tbody tr td .tbodyText .actions button.btn-theme-view.btn {
	border: 1px solid #8C838C;
	height: 44px;
	background-color: #FBF8F8;
	margin-right: 10px;
	width: 44px;
}

.cp_table.dataFields table tbody tr td .tbodyText .actions button.btn-theme-delete.btn {
	height: 44px;
	border: 1px solid #CFC8CF;
	width: 44px;
	background-color: #fff;
}

.cp_table.dataFields table tbody tr.disabled {}

.cp_table.dataFields table tbody tr.disabled .tbodyText {}

.cp_table.dataFields table tbody tr.disabled .tbodyText i.ico {}

.cp_table.dataFields table tbody tr.disabled .tbodyText i.ico rect {
	fill: #CFC8CF;
}

.cp_table.dataFields table tbody tr.disabled .tbodyText i.ico rect:first-child {
	fill: #FBF8F8;
	stroke: #FBF8F8;
}


.cp_table.dataFields table tbody tr .tbodyText input.form-control:disabled {
	background-color: #FBF8F8;
	border-color: #CFC8CF;
	color: #ACA6A6;
}

.cp_table.dataFields table tbody tr .tbodyText input.form-control:disabled::placeholder {
	color: #CFC8CF;
}

.cp_table.dataFields table tbody tr .tbodyText select.form-select:disabled {
	background-color: #FBF8F8;
	border-color: #CFC8CF;
	color: #ACA6A6;
}

.cp_table.dataFields table tbody tr.disabled .tbodyText .actions {}

.cp_table.dataFields table tbody tr.disabled .tbodyText .actions button.btn-theme-delete.btn {
	border-color: #FBF8F8;
	background-color: #FBF8F8;
}

.cp_table.dataFields table tbody tr.disabled .tbodyText .actions button.btn-theme-delete.btn i.ico {}

.cp_table.dataFields table tbody tr.disabled .tbodyText .actions button.btn-theme-delete.btn i.ico svg {}

.cp_table.dataFields table tbody tr.disabled .tbodyText .actions button.btn-theme-delete.btn i.ico svg path {
	stroke: #CFC8CF;
}

.cp_table.dataFields table tbody tr td .tbodyText .actions button.btn-theme-view.btn:disabled {
	background-color: #FBF8F8;
	border-color: #FBF8F8;
}

.cp_table.dataFields table tbody tr td .tbodyText .actions button.btn-theme-view.btn:disabled i.ico {}

.cp_table.dataFields table tbody tr td .tbodyText .actions button.btn-theme-view.btn:disabled i.ico svg {}

.cp_table.dataFields table tbody tr td .tbodyText .actions button.btn-theme-view.btn:disabled i.ico svg path {
	stroke: #CFC8CF;
}

.cp_table.dataFields table tbody tr td .tbodyText .actions button.btn-theme-delete.btn:disabled {
	border-color: #FBF8F8;
	background-color: #FBF8F8;
}

.cp_table.dataFields table tbody tr td .tbodyText .actions button.btn-theme-delete.btn:disabled i.ico {}

.cp_table.dataFields table tbody tr td .tbodyText .actions button.btn-theme-delete.btn:disabled i.ico svg {}

.cp_table.dataFields table tbody tr td .tbodyText .actions button.btn-theme-delete.btn:disabled i.ico svg path {
	stroke: #CFC8CF;
}


.cp_table.dataFields .form-switch input.form-check-input {
	background: #FFFFFF;
	border-color: #1A171A;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='4' cy='4' r='4' fill='%232C292C'/%3e%3c/svg%3e ");
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 23px;
	width: 50px;
	height: 30px;
	box-shadow: 0 0 0 0;
	border-width: 2px;
	transition: all 0.3s;
}

.cp_table.dataFields .form-switch input.form-check-input:checked {
	background: #1A171A;
	border-color: #1A171A;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='4' cy='4' r='4' fill='%23FFFFFF'/%3e%3c/svg%3e");
	background-position: right center;
	background-repeat: no-repeat;
	background-size: 23px;
    width: 50px;
    height: 30px;
    box-shadow: 0 0 0 0;
    border-width: 2px;
}


#teams-table {
	vertical-align: middle;
}
#teams-table .table-avatar {
	width: 44px;
	height: 44px;
	border-radius: 50%;
	background-color: #333;
	color: #fff;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

#teams-table .react-select__control.react-select__control--is-disabled {
    background: #fff;
    border-color: #fff;
}

#teams-table .react-select__control.react-select__control--is-disabled .react-select__indicators,
#teams-table .react-select__control.react-select__control--is-disabled .react-select__multi-value__remove {
    opacity: 0;
}
#teams-table select.form-select:disabled {
	background: #fff;
	border-color: #fff;
}


.team-add-page {
	display: flex;
    flex-direction: row;
    justify-content: space-evenly;
	margin-top: 2rem;
	flex-wrap: wrap;
}

.team-add-page .divider {
	display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
}
.team-add-page .divider span {
	border: 1px dashed #ccc;
    padding: 10px;
    border-radius: 50%;
    background: #f5f5f5;
	z-index: 2;
}
.team-add-page .divider::before {
	content: '';
    width: 1px;
    height: 80%;
    background-color: #ccc;
    display: block;
    position: absolute;
	z-index: 1;
}


.team-add-page label.form-label {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4d474d;
}

.team-add-page input.form-control {
	border: 1px solid #ACA6A6;
	height: 50px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	/* color: #CFC8CF; */
	box-shadow: 0 0 0 0;
}

.team-add-page select.form-select {
	border: 1px solid #ACA6A6;
	height: 50px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	/* color: #CFC8CF; */
	box-shadow: 0 0 0 0;
}
.team-add-page .react-select__control {
    min-height: 55px;
}

/* Domains */
#domain-table {
	vertical-align: middle;
}
#domain-table .domain-avatar {
	width: 44px;
	height: 44px;
	border-radius: 50%;
	background-color: rgba(220, 53, 69, 0.2);
	color: #dc3545;
	color: #fff;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
#domain-table .domain-avatar.authenticated {
	background-color: rgba(25, 135, 84, 0.2);
	color: #198754;
}
#domain-config-table thead tr {
	border-bottom: 1px solid #ccc;
}
#domain-config-table thead th {
	padding-top: 2rem;
}
#domain-config-table code {
	color: #6c757d;
}

/* Package */
.packages-container {
	display: flex;
	align-items: stretch;
	justify-content: center;
	padding: 3rem;
	padding-top: 2rem;
	gap: 2rem;
}
.package-item {
	border: 1px solid #aca6a6;
	border-radius: 6px;
	background: #fff;
	width: 350px;
	text-align: left;
}
.package-item h4 {
	font-weight: 600;
}
.package-item h1 {
	font-size: 3.2rem;
}
.package-item ul {
	list-style: none;
	padding: 0;
	margin: 0;
	text-align: left;
}
.package-item ul li {
	font-size: 0.9rem;
	padding-left: 2rem;
	margin-bottom: 10px;
	display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.package-item ul li i {
	margin-right: 10px;
}

.active-event-popup .statusBox {
	border: 1px solid #19BD9D;
	background-color: #E0FFF9;
	width: 70px;
	height: 24px;
	padding: 0px 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 40px;
	margin: 10px auto 12px;
}
.active-event-popup .statusBox span.dot {
	background-color: #19BD9D;
	width: 6px;
	height: 6px;
	display: block;
	border-radius: 50%;
	margin: 0 4px 0 0;
}


/* ************************ */
/* Badge Creator App */
.badge-creator-app h5 {
	margin-bottom: 2rem;
}
.badge-creator-app .elements-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.badge-creator-app .elements-container .elements-item {
	display: flex;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    padding: 10px 0 5px;
    border-radius: 10px;
    flex-direction: column;
    width: 80px;
    align-items: center;
    justify-content: center;
	cursor: pointer;
	background: #fff;
	border: 0;
	margin: 10px;
}

.badge-creator-app .canvas-area {
	background: #eee;
	border-radius: 6px;
	padding: 10px;
}

.badge-creator-app .canvas-area .size-selector {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.badge-creator-app .canvas-area .size-selector input,
.badge-creator-app .canvas-area .size-selector select,
.badge-creator-app .canvas-area .size-selector button {
	border-radius: 0;
}

.badge-creator-app .canvas-area .size-selector select:disabled {
	background: #1c1a1c;
	color: #fff;
}

.badge-creator-app .canvas-area .canvas-container {
	padding: 30px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.badge-creator-app .canvas-area .canvas-container .canvas {
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	padding: 5mm;
	/* transform: scale(1.1); */
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.badge-creator-app .canvas-area .canvas-container .canvas .line {
	padding: 5px;
	width: 100%;
	height: 10mm;
	border: 1px dashed #ccc;
	white-space: nowrap;
    /* overflow: hidden; */
	overflow-x: clip;
    overflow-y: visible;
}
.badge-creator-app .canvas-area .canvas-container .canvas .line.selected {
	border-color: rgb(77, 185, 207);
    background: rgba(77, 185, 207, 0.15);
}

.badge-creator-app .toolbox-area {
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.badge-creator-app .toolbox-area .toolbox-container {
	width: 100%;
}
.badge-creator-app .toolbox-area .toolbox-container button {
	/* margin-right: 7px; */
	display: inline;
	border: 0;
	padding: 0;
}
/* ************************ */



/* ************************ */
/* Admin Guest List View Page */
.page-guest-list-view .list-toolbar button {
	min-width: 150px;
	border-radius: 0;
}
.page-guest-list-view .list-toolbar .input-group .input-group-text {
	border-radius: 0;
	background: #fff;
}
.page-guest-list-view .list-toolbar .input-group input {
	border-radius: 0;
	outline: 0;
	box-shadow: none !important;
	border-left: 0;
	border-color: #dee2e6 !important;
}

.page-guest-list-view table th {
	color: #777;
}
.page-guest-list-view table th:not(:last-child) .dropdown {
	visibility: hidden;
}
.page-guest-list-view table th:hover .dropdown {
	visibility: visible;
}
.page-guest-list-view .dropdown-menu {
	background-color: #f3f3f3;
	border-radius: 0;
	min-width: 250px;
	border: 0;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}
.page-guest-list-view .dropdown-menu .dropdown-item {
	padding: 10px 16px;
}

.page-guest-list-view table.table-hover > tbody > tr.selected > * {
	background-color: #f3f3f3;
}
/* ************************ */



/* ************************ */
/* Email Template */
.page-email-template .page-container {
	background-color: #fff;
    border: 2px solid #fff;
    border-radius: 18px;
    box-shadow: 0px 8px 24px 0px #ACA6A63D;
    padding-top: 2px;
}
.page-email-template table {
    border-collapse: separate;
    border-spacing: 0em 0.2em;
    padding: 0px 5px;
}
.page-email-template table thead th {
    background: #fbf8f8;
    /* color: #ACA6A6; */
    color: #444;
    height: 60px;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.025em;
}
.page-email-template table th,
.page-email-template table td {
	vertical-align: middle;
}

.createNewEmailTemplateModal {
	z-index: 99999;
}
.createNewEmailTemplateModal .modal-dialog .modal-content .modal-body {
	padding: 30px;
}

.createNewEmailTemplateModal .modal-dialog .modal-content .modal-body label.form-label {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	color: #4d474d;
}

.createNewEmailTemplateModal .modal-dialog .modal-content .modal-body input.form-control {
	border: 1px solid #ACA6A6;
	height: 50px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	/* color: #CFC8CF; */
	box-shadow: 0 0 0 0;
}

.createNewEmailTemplateModal .modal-dialog .modal-content .modal-body select.form-select {
	border: 1px solid #ACA6A6;
	height: 50px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.025em;
	text-align: left;
	/* color: #CFC8CF; */
	box-shadow: 0 0 0 0;
}


.templatePlaceholderModal {
	z-index: 99999;
}

.templatePlaceholderModal .modal-dialog .modal-content .modal-body input.form-control {
	border: 1px solid #ACA6A6;
	font-family: Inter;
	text-align: left;
	box-shadow: 0 0 0 0;
}

/* REPORT */

.page-email-template-report table th {
	color: #777;
	vertical-align: text-bottom;
}
.page-email-template-report table th:first-child,
.page-email-template-report table td:first-child {
	width: 60px;
}
.page-email-template-report table.table-hover > tbody > tr.selected > * {
	background-color: #f3f3f3;
}
.page-email-template-report table .form-check-input:checked {
    background-color: #777;
    border-color: #777;
}
.page-email-template-report .email-status-row {
	display: flex;
	flex-direction: row;
	gap: 5px 15px;
	align-items: center;
	/* justify-content: space-between; */
    flex-wrap: wrap;
}
.page-email-template-report .email-status-row > div {
	display: flex;
	align-items: center;
	min-width: 160px;
}
.page-email-template-report .email-status-row > div::before {
	content: '';
	display: block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #777;
	margin-right: 5px;
}
.page-email-template-report .email-status-row > div.delivered::before {
	background-color: rgb(109, 166, 128);
}
.page-email-template-report .email-status-row > div.opened::before {
	/* background-color: #2c292c; */
	background-color: rgb(77, 121, 91);
}
.page-email-template-report .email-status-row > div.clicked::before {
	background-color: rgb(55, 89, 66);
}
.page-email-template-report .email-status-row > div.pending::before {
	background-color: rgb(255, 152, 0);
}
.page-email-template-report .email-status-row > div.not_delivered::before {
	background-color: rgb(199, 111, 104);
}
.page-email-template-report .email-status-row > div.invalid_email::before {
	background-color: rgb(236, 34, 20);
}
.page-email-template-report .email-status-row > div.missing_email::before {
	background-color: rgb(152, 74, 67);
}
.page-email-template-report .email-status-row > div button {
	padding-left: 0;
	padding-right: 0;
	color: #333;
}
/* ************************ */


/* ************************ */
/* Accounts */
.profile-image-container {
    width: 150px;
    height: 150px;
    margin: 0 auto;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	border-radius: 50%;
}

.profile-image-container img {
    /* height: 100%; */
    /* width: 100%; */
}
.profile-image-container .image-avatar {
	height: 100%;
    width: 100%;
	/* border-radius: 50%; */
	background-color: #333;
	/* background-color: #aca6a7; */
	color: #fff;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2rem;
}

.profile-image-container .image-upload-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
	border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.9);
    opacity: 1;
	display: none;
	cursor: pointer;
}
.profile-image-container .image-upload-btn input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
	cursor: pointer;
}
.profile-image-container:hover .image-upload-btn {
	display: flex;
}
/* ************************ */
